<template>
	<div class="mb-sm-5 mb-3">
		<h5 class="d-flex align-items-center mb-4">
			{{ title }}
			<span v-if="selectedFilters && selectedFilters.length" class="ml-2 selected-count w-color-primary">
				({{ selectedFilters.length }})
			</span>
		</h5>

		<w-loadable :items="filters" />
		<div v-if="filters">
			<!-- <b-form-checkbox v-model="selectAll" @change="selectAllFilters()">
				<span>Všetky</span>
			</b-form-checkbox> -->
			<b-form-checkbox-group 
				v-model="selectedFilters" :options="showAll ? filters : filters.slice(0, showThreshold)" 
				value-field="slug" text-field="name" stacked
				@change="filtersChanged()"
			/>

			<a
				v-if="filters.length > showThreshold"
				class="my-2 d-flex align-items-center font-weight-bold w-color-black" href="#"
				@click.prevent="toggleShowAllFilters(filters)"
			>
				Zobraziť {{ showAll ? 'menej' : 'viac' }}
				<svg-caret-icon class="ml-2" :class="{ 'rotate180': showAll }" />
			</a>
		</div>
	</div>
</template>

<script>
export default {
	components: {
		'svg-caret-icon': () => import('@/plugins/appzmudri/_theme/icon/caret.svg?inline')
	},

	props: {
		title: { required: true, type: String },
		filters: { default: null, type: Array },
		showThreshold: { default: 5, type: Number }
	},

	data() {
		return {
			// selectAll: false,
			selectedFilters: [],
			showAll: false
		}
	},

	mounted() {
		// Hack? init filters with value passed from parent (not as prop)
		this.selectedFilters = this.$attrs.selectedFilters
	},

	methods: {
		// selectAllFilters() {
		// 	if (this.selectAll) {
		// 		this.selectedFilters = this.filters.map(f => f.slug)
		// 	} else {
		// 		this.selectedFilters = []
		// 	}

		// 	this.filtersChanged()
		// },

		filtersChanged() {
			this.$emit('update:selectedFilters', this.selectedFilters)
		},

		toggleShowAllFilters() {
			this.showAll = !this.showAll
		}
	}
}
</script>

<style lang="scss" scoped>
.selected-count {
	font-size: 0.875rem;
}

a {
	padding-left: 2rem;
	font-size: 1rem;
}

.custom-checkbox, ::v-deep .custom-checkbox {
	padding-bottom: 0.5rem;
	padding-top: 0.5rem;
}

svg {
	transition: 0.3s ease;
}

.rotate180 {
	transform: rotate(180deg);
}
</style>
